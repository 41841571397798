@font-face {
  font-family: "#{$mdi-font-name}";
  src: url("#{$mdi-font-path}/#{$mdi-filename}-webfont.eot?v=#{$mdi-version}");
  src: url("#{$mdi-font-path}/#{$mdi-filename}-webfont.eot?#iefix&v=#{$mdi-version}")
      format("embedded-opentype"),
    url("#{$mdi-font-path}/#{$mdi-filename}-webfont.woff2?v=#{$mdi-version}")
      format("woff2"),
    url("#{$mdi-font-path}/#{$mdi-filename}-webfont.woff?v=#{$mdi-version}")
      format("woff"),
    url("#{$mdi-font-path}/#{$mdi-filename}-webfont.ttf?v=#{$mdi-version}")
      format("truetype"),
    url("#{$mdi-font-path}/#{$mdi-filename}-webfont.svg?v=#{$mdi-version}##{$mdi-filename}#{$mdi-font-weight}")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
