.page-wrapper .page-body-wrapper footer {
  margin-left: 0;
}

.page-main-header .main-header-right .nav-right > ul > li:first-child {
  width: initial;
  margin: initial;
  padding: 20px;
}

.page-main-header .main-header-right .nav-right.right-menu ul li .user-header img {
  width: 68px !important;
}

.page-wrapper .page-body-wrapper .page-body {
  margin-left: 90px;
  padding: 0 20px 0 10px;
}

[main-theme-layout="box-layout"] .page-wrapper .page-body-wrapper footer {
  margin-left: 88px;
}

.recent-badge {
  margin: 5px;
}

.recent-table table tbody tr td:nth-child(3) .recent-badge {
  width: 100px;
}

.activity-table table tbody tr td {
  padding-top: 0;
  padding-bottom: 0;
}

.identicon {
  border-radius: 38px;
  vertical-align: middle;
  filter: drop-shadow(2px 3px 9px rgba(21, 141, 247, 0.4));
}

.inline {
  display: inline;
}

.centered {
  text-align: center;
  margin: 0 auto;
}

a.nav-link {
  cursor: pointer;
}

.activity-table .pool-names {
  padding: 1.50em 0;
}

.more-info {
  display: inline;
  font-family: "Roboto Slab", serif;
}

.blog-box .blog-details .blog-social li:first-child {
  border: none;
}

.mt-0 {
  margin-top: 0;
}

.main-metric .card-body {
  padding: 35px;
  margin-top: 0;
}

.recent-images img {
  width: 64px;
  height: 64px;
  padding: 12px;
  border-radius: 38px;
  filter: initial !important;
}

.card-header.rtl {
  left: initial;
  right: 15px;
}

.card-header.centered {
  right: initial;
  margin: 0 auto;
  left: 33%;
  width: 33%;
  text-align: center;
}

button {
  font-family: "Roboto Slab", serif;
}

button.connect-btn {
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 0.5em 2.25em;
  font-size: 1.5em;
}


.page-main-header .main-header-right {
  min-height: 88px;
}

tr.portfolio-entry td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


span.large {
  font-size: 1.18em;
}

.text-title {
  color: $primary-color;
  font-weight: 800;
}

.input-air-primary {
  padding: 1em;
}

.info-text {
  font-size: 0.8em;
  max-width: 200px;
  color: orangered;
}

.crm-activity ul.dates li {
  font-size: 1.2em;
}

.center-block {
  display: block;
  text-align: center;
}

.dates {
  margin: 0.33em 0;
}


.addrs-manual {
  border-radius: 18px;
  padding: 1.28em;
  font-size: 18px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 48px;
}

.share-ctr {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}

.rounded-bg {
  border-radius: 22px;
}

.welcome-popup .contain {
  padding-top: 0;
}

.center-block-button {
  display: block;
  text-align: center;
  margin: 0.5em auto 0;
}

.socials {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.anchor-link {
  cursor: pointer;
  color: $primary-color;
}

.crm-activity span {
  font-size: 16px;
  border-radius: initial;
  border: 0;
  padding: 0;
  line-height: 0;
}

.border-tab-primary.nav-tabs .nav-item {
  width: 50%;
}

li.digits {
  font-size: 18px;
}

.media .circle {
  font-size: 24px;
  border: 2px solid;
  padding: 0 10px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 2;
}

.crm-activity {
  span.small, &.small, li.digits, h5 {
    font-size: 14px;
  }
}

ul.dates {
  margin: 0;
}

.card .card-header.custom {
  padding: 30px;
}


.btc-buy-sell .btc-amount {
  font-size: 15px;
}

.badge svg {
  width: 18px;
  height: 18px;
}

.relative {
  position: relative;
}

.tooltip-stats {
  position: absolute;
  top: 7px;
  left: 22px;
  color: rebeccapurple;
}

.tooltip-stats-rtl {
  position: absolute;
  top: 0px;
  right: -15px;
  color: rebeccapurple;
}

.tooltip-arrow, .tooltip > .tooltip-inner {
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: $info-color;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $info-color;
}

.tooltip-inner {
  padding: 7px 7px;
  color: white;
  text-align: center;
  background: $info-color;
  border: $info-color;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.btc-sell h4, .btc-sell h5 {
  padding: 15px 0;
}

.block {
  display: block;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 666px;
  }
}

.btc-table-xs {
  padding-left: 5px;
  padding-right: 5px;
}

.banner-ad {
  width: 100%;
}

.gitcoin {
  max-width: 300px;
  margin: 0 1em;

  a {
    display: inline-flex;
  }

  img {
    width: 45px;
    height: 45px;
    display: inline-block;
  }

  .gitcoin-color {
    background: #3e00ff;
  }

  span {
    vertical-align: middle;
    margin-left: 1em;
    display: inline-block;
  }
}

.breadcrumb-colored .breadcrumb-item + .breadcrumb-item::before {
  opacity: 1;
}

.pool-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.selector-img {
  width: 48px;
  height: 48px;
  border-radius: 38px;
  margin-right: 0.5em;
}

.select-exchanges {
  padding: 15px !important;
}

.m-checkbox-inline label {
  margin-left: 20px;
}

.custom-radio-ml {
  display: flex;
}

.shim {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.bounded-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.flex-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text-action {
}

.small-padding {
  padding: 30px !important;
}

.font-muted {
  color: #bbb;
}

table.results-table thead th {
  text-align: center;
  vertical-align: middle;
  font-size: 1.18em;

  &.larger {
    font-size: 1.28em;
  }
}

.gains-col {
  max-width: 153px;
  position: relative;
}

.underline {
  text-decoration: underline;
}

.tml {
  font-size: 17px;
  margin-bottom: 0.12rem;
  color: $primary-color;
}

@media (min-width: 1280px) {
  [main-theme-layout="box-layout"] .page-wrapper .page-body-wrapper .iconsidebar-menu .iconMenu-bar li {
    position: relative;
  }
}

.pricing-block .pricing-inner ul {
  list-style-type: none;

  li h6 {
    line-height: 25px;
    margin-bottom: 13px;
  }
}


b.accented {
  font-size: 1.1em;
  font-weight: bolder;
}

.iconsidebar-menu, .page-main-header, .footer {
  z-index: 0 !important;
}

.card .card-footer {
  padding: 20px 30px;
}

.pool-name {
  padding: 20px 0;

  i.fa {
    color: orangered;
    vertical-align: top;
    top: -21px;
    display: inline-block;
    position: relative;
    right: 15px;
  }
}


.pricing-block .pricing-inner ul li h6 {
  padding-left: 22px;
  padding-right: 22px;
}


.pro-feature-badge {
  position: relative;
  top: -10px;
  right: -5px;
  font-size: 0.6em;
}

.info-box {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  align-items: center;
}

.xs {
  font-size: 55%;
}

h4.sm-padding {
  padding: 12px 0 0;
}

.pools-count {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}

.centered-button-group {
  text-align: center;
  display: block;
}

.charts {
  &.table th {
    padding: 0.99rem 0.25rem;
  }

  &.table td {
    padding: 0.38rem 0.3rem;
  }

  td, th {
    text-align: center;
  }

  th.limit-width {
    max-width: 230px;
  }

  td {
    vertical-align: middle;
  }


  &.table thead th {
    vertical-align: middle;
  }

  .col.xs {
    width: 75px !important;
    max-width: 75px !important;
  }

  .col.sm {
    width: 100px !important;
    max-width: 100px !important;
  }

  .col.md {
    width: 150px !important;
    max-width: 150px !important;
  }

  .col.lg {
    width: 200px !important;
    max-width: 200px !important;
  }
}

.modal-content.transparent {
  background-color: transparent;
  border: 0;
}


.btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
  background-color: $primary-color;
  border-color: $primary-color;
  color: white;
}

.custom {

  .rbt-input-multi.form-control[disabled] {
    background-color: #fff;
    border-radius: 28px;
  }

  .rbt-input-multi .rbt-input-wrapper {
    padding: 12px;
  }

  .rbt-token {
    padding: 10px 25px;
    margin: 5px;
  }
}

.time-period-btn {
  font-size: 18px;
}

table .help-bubble {
  cursor: pointer;
  position: absolute;
  top: 5px;
}

.btn-pill.more-info {
  padding: 5px;

  svg {
    vertical-align: middle;
  }

}

.border-tab-primary.nav-tabs .nav-item.full-width {
  width: 100%;
}

.btc-table-xs-r {
  padding-left: 0px;
  padding-right: 5px;
}

.btc-table-xs-rl {
  padding-left: 0px;
  padding-right: 15px;
}

.warning-icon {
  position: relative;
  top: 3px;
  right: -3px;
}

.external-link-icon {
  position: relative;
  top: 3px;
  left: 5px;
}

.switch-light-bg {
  background: $primary-color;

  .react-switch-button {
    background-image: url("https://s3-us-west-2.amazonaws.com/acf-uploads/iconfinder_weather_3_2682848.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}

.switch-dark-bg {
  background: #fff;

  .react-switch-button {
    background-image: url("https://s3-us-west-2.amazonaws.com/acf-uploads/iconfinder_sun-day-bright-light_2205209.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}


label.react-switch-label {
  text-align: center;
  display: block;
  margin: 1.58em auto;
}

.badge-theme-switcher {
  position: relative;
  right: -45px;
  top: 12px;
}

.dark-only {
  .card {
    background-color: $dark-card-background;
    box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  }

  .btc-buy-sell .btc-amount {
    background-color: darkslateblue;
  }

  .border-tab-primary.nav-tabs .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.3);
  }
}
