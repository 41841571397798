/**=====================
    58. Dashboard Crypto CSS Start
    ==========================**/
.card-bg {
  background-color: #f6f3fa;
  &.card .card-header {
    background-color: transparent;
  }
  .media {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: calc(100% - 35px);
    align-items: center;
    padding: 0 30px;
    right: 0;
    margin: 0 auto;
    h5 {
      color: $white;
      font-weight: 700;
      padding-top: 12px;
      margin-left: 10px;
    }
  }
}
.crypto-slider {
  .slick-slider {
    .slick-list {
      margin: 0 -15px;
      .slick-track {
        .slick-slide {
          > div {
            padding: 0 15px;
            .item {
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
#bitcoinchart-1 {
  .apexcharts-tooltip {
    background: $transparent-color;
    border: none;
    &.apexcharts-theme-light {
      .apexcharts-tooltip-title {
        background: lighten($danger-color, 10%);
        color: $white;
        border-bottom: none;
        margin-bottom: 0;
      }
    }
    .apexcharts-tooltip-series-group {
      background: lighten($danger-color, 15%);
      border-bottom: none;
      color: $white;
    }
  }
}
#bitcoinchart-2 {
  .apexcharts-tooltip {
    border: none;
    background: lighten($primary-color, 15%);
    color: $white;
  }
  .apexcharts-tooltip .apexcharts-tooltip-title {
    background: lighten($primary-color, 10%);
    border-bottom: none;
    color: $white;
  }
}
#bitcoinchart-3 {
  .apexcharts-tooltip {
    border: none;
    background: #ffd85d;
    color: $white;
  }
  .apexcharts-tooltip .apexcharts-tooltip-title {
    background: #ffc200;
    border-bottom: none;
    color: $white;
  }
}
#candlestick-chart {
  margin-bottom: -20px;
  .apexcharts-yaxistooltip,
  .apexcharts-xaxistooltip {
    background: lighten($danger-color, 10%);
    border: none;
    border-radius: 8px;
    color: $white;
  }
  [class*="apexcharts-yaxistooltip-"] {
    &:after {
      border-left-color: lighten($danger-color, 10%);
    }
    &:before {
      border-left-color: transparent;
    }
  }
  .apexcharts-xaxistooltip-bottom {
    &:after {
      border-bottom-color: lighten($danger-color, 10%);
    }
    &:before {
      border-bottom-color: transparent;
    }
  }
}

.bck-gradient-primary {
  background-image: $gradient-primary-direction;
  box-shadow: 2.5px 4.33px 18px 0 rgba($primary-color, 0.25);
  color: $white;
}
.bck-gradient-secondary {
  background-image: $gradient-secondary-direction;
  box-shadow: -0.5px 4.33px 16px 0px rgba(251, 46, 99, 0.25);
  color: $white;
}
.bck-gradient-warning {
  background-image: $gradient-warning-direction;
  box-shadow: -0.5px 4.33px 16px 0px rgba(255, 83, 19, 0.25);
  color: $white;
}
.crypto-graph-card {
  .media {
    align-items: center;
    .bitcoin-graph-content {
      span {
        color: $light-font;
      }
    }
    .badge {
      background-color: #f3f9ff;
    }
    .right-setting {
      .setting-dot {
        .setting-bg {
          background-color: $white;
          box-shadow: 2.5px 4.33px 18px 0 rgba(251, 46, 99, 0.1);
        }
      }
      h5 {
        margin-top: 8px;
        font-size: 18px;
      }
    }
    .rounded-icon {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      margin-right: 15px;
      border-radius: 100%;
      i {
        font-size: 25px;
      }
      &:hover {
        background-size: 100%;
      }
    }
  }
  &.secondary-crypto {
    .media {
      .badge {
        background-color: #fff6f8;
      }
    }
  }
  &.warning-crypto {
    .media {
      .badge {
        background-color: #fff8f3;
      }
    }
  }
}
.crypto-table-market {
  padding: 0 20px;
  table {
    thead {
      tr {
        th {
          font-weight: 700;
          text-transform: uppercase;
          border-bottom: none;
          background-color: $light-body-bg-color;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px;
          &:nth-child(3) {
            color: $light-font;
          }
          &:last-child {
            text-align: right;
          }
          .badge {
            font-weight: normal;
          }
        }
        &:nth-child(4) {
          td {
            &:nth-child(4) {
              span {
                background-color: rgba($success-color, 0.1);
              }
            }
          }
        }
        &:first-child {
          td {
            &:nth-child(4) {
              span {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
        }

        &:nth-child(2) {
          td {
            &:nth-child(4) {
              span {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
        }
        &:nth-child(3) {
          td {
            &:nth-child(4) {
              span {
                background-color: rgba($secondary-color, 0.1);
              }
            }
          }
        }
        &:nth-child(5) {
          td {
            &:nth-child(4) {
              span {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
        }
      }
    }
  }
}
.coin-card {
  &.card-body {
    padding: 20px !important;
  }
}
.btc-earning .ct-series.ct-series-b .ct-line {
  stroke: #dbdfe9;
  stroke-width: 2px;
  stroke-dasharray: 5px 2px;
  stroke: #dbdfe9;
  stroke-width: 2px;
  stroke-dasharray: 16px 12px;
  animation: btc-dashoffset 300ms infinite linear;
  opacity: 0.5;
}
@keyframes btc-dashoffset {
  0% {
    stroke-dashoffset: 22px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.btc-earning {
  height: 400px;
  padding-top: 80px;
  margin-bottom: -15px;
  svg {
    .ct-series-a,
    .ct-series-b {
      .ct-point,
      .ct-line,
      .ct-bar,
      .ct-slice-donut {
        stroke: $white;
      }
    }
  }
}
.btc-overview {
  .text-bg {
    position: absolute;
    top: 26%;
    right: 21%;
    span {
      font-size: 200px;
      color: rgba(255, 255, 255, 0.1);
      font-weight: 700;
    }
  }
  .rounded-icon {
    background-color: rgba($theme-default-color, 0.7);
  }
}
#bitcoinchart-1,
#bitcoinchart-2,
#bitcoinchart-3 {
  margin-bottom: -30px;
  margin-top: -60px;
  .apexcharts-xaxistooltip {
    display: none;
  }
}
.bitcoin-1 {
  background-color: $secondary-color;
}
.btc-buy-sell {
  .btc-amount {
    padding: 12px 15px;
    border-radius: 8px;
    background-color: whitesmoke;
  }
  li {
    padding-bottom: 3px;
  }
}
.btc-buy {
  padding-top: 15px;
  .btn {
    box-shadow: -0.5px 4.33px 16px 0px rgba($secondary-color, 0.25);
    position: relative;
    margin-left: 15px;
    padding: 12px 50px;
    i {
      padding: 17px 15px;
      background: $white;
      color: $secondary-color;
      vertical-align: middle;
      position: absolute;
      left: -14px;
      top: -2px;
      border-radius: 25px;
    }
  }
}
.btc-sell {
  .btn {
    &:nth-child(2) {
      padding: 12px 50px;
      z-index: 1;
    }
  }
  .btn {
    padding: 12px 50px;
    margin-right: -40px;
    position: relative;
    z-index: 9;
    background-color: rgba($secondary-color, 0.1);
    box-shadow: -0.5px 4.33px 16px 0px rgba($secondary-color, 0.25);
  }
}
.more-btn {
  .btn-block {
    position: relative;
    font-size: 22px;
  }
  .btn-light {
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn {
    &:nth-child(2) {
      padding: 27px 21px;
      margin-right: 13px;
      background-color: $white !important;
      color: $black;
      border-radius: 50% 0 0 50%;
      &:focus,
      &:hover,
      &:active {
        border-color: transparent !important;
      }
    }
  }
  .btn {
    padding: 22px 50px;
    margin-right: -50px;
    padding-left: 0;
    border-radius: 25px;
  }
}
.Add-card {
  .card {
    border-radius: 20px;
  }
}
.card-arrow {
  box-shadow: $card-box-shadow;
}
.add-arrow {
  box-shadow: $card-box-shadow;
}
.right-angle {
  background-color: $white;
  border-radius: 20px;
  padding: 20px;
  i {
    padding-left: 16px;
  }
}
.btc-table {
  .card-header {
    padding: 15px 10px;
  }
  .card-body {
    padding: 10px;
  }
}
@media screen and (max-width: 1660px) {
  .card-bg {
    .media {
      padding: 0;
    }
  }
  .add-arrow {
    .more-btn {
      .btn-secondary {
        display: none;
      }
      .btn-light {
        width: 100%;
        border: 1px solid #f4eefd !important;
        border-radius: 15px;
        padding: 27px 21px;
      }
    }
  }
  .more-btn {
    .btn-light {
      position: relative;
    }
  }
  .crypto-table-market {
    table {
      tr {
        td {
          &:nth-child(2),
          &:nth-child(3) {
            min-width: 107px;
          }
        }
      }
    }
  }
}
@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 991px) {
  .btc-overview {
    .text-bg {
      position: absolute;
      top: 24%;
      right: 23%;
    }
  }
  #bitcoinchart-1,
  #bitcoinchart-2,
  #bitcoinchart-3 {
    margin-top: -40px;
  }
  .card-bg {
    .media {
      bottom: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  .btc-overview {
    span {
      font-size: 145px !important;
    }
    .text-bg {
      position: absolute;
      top: 30%;
      right: 25%;
    }
  }
  .Add-card {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 575px) {
  .crypto-table-market {
    table {
      thead {
        tr {
          th {
            padding: 15px;
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 100px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              min-width: 97px !important;
            }
            padding: 15px;
          }
        }
      }
    }
  }
  .btc-table {
    .card-header {
      padding-bottom: 36px !important;
    }
    .btc-buy {
      padding-top: 0 !important;
    }
    .btc-sell {
      .btn {
        &:nth-child(2) {
          padding: 12px 50px;
          z-index: 1;
          margin-right: 0;
        }
      }
    }
  }
  .btc-overview {
    .card-header {
      .text-right {
        text-align: left !important;
        float: none;
        margin-top: 20px;
      }
    }
    .text-bg {
      position: absolute;
      top: 38%;
      right: 22%;
    }
    .media {
      display: unset;
    }
  }
  .btc-table-xs {
    margin-bottom: 15px;
  }
  .btc-table-xs-l {
    padding-left: 15px;
  }
  .Add-card {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 360px) {
  .btc-table {
    .card-header {
      padding: 25px 35px !important;
    }
  }
  .btc-overview {
    .text-bg {
      position: absolute;
      top: 42%;
      right: 18%;
    }
  }
}

/**=====================
    58. Dashboard Crypto  CSS End
    ==========================**/
